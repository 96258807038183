<!--
 * @Description: 我的订单页面组件
 * @Author: hai-27
 * @Date: 2020-02-20 17:21:54
 * @LastEditors: hai-27
 * @LastEditTime: 2020-02-27 13:36:27
 -->
<template>
	<div class="uc-box uc-main-box width1225">
		<div class="uc-content-box order-list-box">
			<!-- 我的订单头部 -->
			<!-- <div class="box-hd">
				<h1 class="title">关于我们</h1>
				<div class="clearfix"></div>
			</div> -->
			<!-- 我的订单头部END -->
			<div class="main">
				<div class="nav">
					<div class="nav_li" :class="current==0?'active':''" @click="current=0">企业介绍<span></span></div>
					<div class="nav_li" :class="current==1?'active':''" @click="current=1">联系我们<span></span></div>
				</div>
				<div class="box_detail">
					<div class="detail" v-if="current==0">
						<p v-html="gywm"></p>
					</div>
					<div class="detail2" v-if="current==1">
						<div class="concat-div">
							<p class="title">联系方式</p>
							<ul class="items clear">
								<li class="item fl boxsiz" v-for="(item,index) in lxwm" :key="index">
									<p class="item-title">{{item.Title}}</p>
									<p>{{item.text1}}</p>
									<p>{{item.text2}}</p>
									<p>{{item.text3}}</p>
									<p>{{item.text4}}</p>
								</li>
							</ul>
						</div>
						<div class="comp-address-div">
							<p class="title">公司地址</p>
							<ul class="items clear">
								<li class="item fl boxsiz" v-for="(item,index) in address" :key="index">
									<p class="item-title">{{item.Name}}</p>
									<p>工作时间：{{item.WorkDate}}</p>
									<p>邮政编码：{{item.YouZhengBianMa}}</p>
									<p>联系电话：{{item.dianhua}}</p>
									<p>传真方式：{{item.chuanzhen}}</p>
									<p>联系地址：{{item.Address}}</p>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
			<!-- 我的订单主要内容 -->

			<!-- 我的订单主要内容END -->
		</div>
	</div>
</template>
<script>
	export default {
		name: 'Order',
		data() {
			return {
				current: 0,
				gywm: {},
				lxwm: [],
				address: [],
			};
		},
		activated() {
			if (this.$route.query.type != undefined) {
				this.current = this.$route.query.type;
				
			}
		},
		created() {
			this.Getconfig()
			this.ContactInfoList()
			this.CompanyAddressList()
		},

		methods: {
			async Getconfig() {
				var data = {
					queryType: 1,
				}
				var res = await this.postdata("/api/News/GetConfig", data);
				if (res.code == 200) {
					this.gywm = res.data.AboutUs;
				}
			},
			async ContactInfoList() {
				var data = {
					"page": 1,
					"limit": 20
				}
				var res = await this.postdata("/api/News/ContactInfoList", data);
				if (res.code == 200) {
					this.lxwm = res.data.items;
				}
			},
			async CompanyAddressList() {
				var data = {
					"page": 1,
					"limit": 20
				}
				var res = await this.postdata("/api/News/CompanyAddressList", data);
				if (res.code == 200) {
					this.address = res.data.items;
				}
			},
		}

	}
</script>


<style lang="less">
	.main {
		.nav {
			display: flex;
			justify-content: center;
			width: 1100px;
			margin: 5px auto 48px;
			padding-top: 16px;
			border-bottom: 2px solid #e4e7ed;
			text-align: center;

			.nav_li {
				font-size: 14px;
				color: #303133;
				cursor: pointer;
				width: 160px;
				height: 50px;
				line-height: 50px;
				position: relative;
				text-align: center;
				transition: all linear 0.1s;

				span {
					display: none;
				}
			}

			.active {
				font-size: 18px;
				color: #09c867;

				span {
					width: 60px;
					height: 2px;
					background: #09c867;
					position: absolute;
					bottom: -2px;
					left: 50%;
					margin-left: -30px;
					display: block;
				}

			}
		}
	}
</style>
<style scoped>
	.uc-box .order-empty {
		margin: 0 auto;
	}

	.uc-box .order-empty .empty {
		height: 300px;
		padding: 0 0 130px 455px;
		margin: 65px 0 0;
		background: url(../../assets/imgs/cart-empty.png) no-repeat 32px 0;
		color: #b0b0b0;
		overflow: hidden;
	}

	.uc-box .order-empty .empty h2 {
		margin: 70px 0 15px;
		font-size: 36px;
	}

	.uc-box .order-empty .empty p {
		margin: 0 0 20px;
		font-size: 20px;
	}

	.uc-box {
		background: #fff
	}

	.uc-box .uc-content-box {
		margin: 0 48px
	}

	.uc-box .uc-content-box .box-hd .title {
		margin: 0;
		font-size: 30px;
		font-weight: 400;
		line-height: 68px;
		color: #757575
	}

	.uc-box .uc-content-box .box-hd .more {
		border-bottom: 0;
	}

	.detail2 .concat-div .title {
		color: #000;
		font-size: 26px;
		padding-left: 16px;
		cursor: inherit;
	}

	.detail2 .concat-div .items {
		color: #333;
		font-size: 14px;
		margin-top: 23px;
	}

	.detail2 .concat-div .items .item:first-child {
		margin-left: 0;
	}

	.detail2 .concat-div .items .item {
		height: 190px;
		width: 270px;
		background-color: #f8f8f8;
		padding-left: 16px;
		margin-left: 8px;
	}

	.detail2 .concat-div .items .item p.item-title.yel {
		color: #ff5a06;
	}

	.detail2 .concat-div .items .item p.item-title {
		font-size: 16px;
		padding-top: 16px;
		font-weight: 600;
		height: 28px;
		line-height: 28px;
		cursor: inherit;
	}

	.detail2 .concat-div .items .item p {
		padding-top: 13px;
		line-height: 20px;
		height: 20px;
	}

	.detail2 p {
		cursor: pointer;
	}

	.detail2 .concat-div .items .item:first-child {
		margin-left: 0;
	}

	.detail2 .comp-address-div {
		margin-top: 43px;
		padding-bottom: 24px;
	}

	.detail2 .comp-address-div .title {
		color: #000;
		font-size: 26px;
		padding-left: 16px;
		cursor: inherit;
	}

	.detail2 .comp-address-div .items {
		margin-top: 23px;
		color: #333;
		font-size: 14px;
	}

	.detail2 .comp-address-div .items .item:first-child {
		margin-left: 0;
	}

	.detail2 .comp-address-div .items .item {
		width: 550px;
		min-height: 224px;
		background: #f8f8f8;
		padding:0 29px 18px 29px;
		margin-left: 7px;
	}

	.detail2 .comp-address-div .items .item p.item-title {
		padding: 18px 0 8px;
		font-weight: 600;
		font-size: 16px;
		cursor: inherit;
	}

	.detail2 .comp-address-div .items .item p {
		margin-top: 4px;
		line-height: 28px;
	}
</style>
